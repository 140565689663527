import { Snackbar, Alert, SlideProps, Slide, IconButton } from "@mui/material";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { v4 as uuid } from "uuid";
import CloseIcon from "@mui/icons-material/Close";

export enum AlertType {
  Success = "success",
  Error = "error",
}
interface AlertInfo {
  id?: string;
  type: AlertType;
  message: string;
}
interface AlertsPresenter {
  showAlert: (props: AlertInfo) => void;
}

const AlertsPresenterContext = createContext<AlertsPresenter>({
  showAlert: () => {},
});

export function AlertsPresenterContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [presentedAlert, setPresentedAlert] = useState<AlertInfo | null>(null);
  const open = presentedAlert !== null;

  const presenter: AlertsPresenter = {
    showAlert: (props) => {
      console.log(`Show alert: ${JSON.stringify(props)} | ${open}`);
      props.id = uuid();
      setPresentedAlert(props);
    },
  };

  const handleClose = () => {
    console.log(`Alert - close`);
    setPresentedAlert(null);
  };
  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
  }

  const closeAction = (
    <>
      <IconButton size="small" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <AlertsPresenterContext.Provider value={presenter}>
        {children}
      </AlertsPresenterContext.Provider>

      <Snackbar
        key={presentedAlert?.id ?? ""}
        open={open}
        TransitionComponent={SlideTransition}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {presentedAlert ? (
          <Alert
            variant="filled"
            // onClose={handleClose}
            severity={presentedAlert.type}
            action={closeAction}
            sx={{ width: "100%" }}
          >
            {presentedAlert.message}
          </Alert>
        ) : (
          <div />
        )}
      </Snackbar>
    </div>
  );
}

export function useAlertsPresenter(): AlertsPresenter {
  const presenter = useContext(AlertsPresenterContext);
  return presenter;
}
