import { FirestoreTimestamp } from "../Aliases";
import { SchoolDetails } from "./ElementSchoolDetailsAssignmentData";

export enum UserRole {
  unverified = "unverified",
  normal = "normal",
  school = "school",
  coach = "coach",
  admin = "admin",
}
export enum AuthClaim {
  projectsReadAll = "projects.read.all",
  projectsEditAll = "projects.edit.all",

  usersReadAll = "users.read.all",
  usersEditAll = "users.edit.all",

  assignmentsReadAll = "assignments.read.all",
  assignmentsEditAll = "assignments.edit.all",
  assignmentsDeleteAll = "assignments.delete.all",

  systemEdit = "system.edit",
}

export function defaultClaimsFor(role: UserRole): AuthClaim[] {
  switch (role) {
    case UserRole.unverified:
      return [];
    case UserRole.normal:
      return [
        AuthClaim.projectsReadAll,
        AuthClaim.assignmentsReadAll,
        AuthClaim.assignmentsEditAll,
        AuthClaim.usersReadAll,
      ];
    case UserRole.school:
      return [
        // Projects read: only shared
        // Assignments read/edit: only shared
        AuthClaim.assignmentsEditAll, // TODO: Edit only shared
        AuthClaim.usersReadAll,
      ];
    case UserRole.coach:
      return [
        AuthClaim.projectsReadAll,
        AuthClaim.assignmentsReadAll,
        AuthClaim.usersReadAll,
      ];
    case UserRole.admin:
      return [
        AuthClaim.projectsReadAll,
        AuthClaim.projectsEditAll,
        AuthClaim.usersReadAll,
        AuthClaim.usersEditAll,
        AuthClaim.assignmentsReadAll,
        AuthClaim.assignmentsEditAll,
        AuthClaim.assignmentsDeleteAll,
      ];
  }
}

export const ALL_CLAIMS = [
  AuthClaim.projectsReadAll,
  AuthClaim.projectsEditAll,
  AuthClaim.usersReadAll,
  AuthClaim.usersEditAll,
  AuthClaim.assignmentsReadAll,
  AuthClaim.assignmentsEditAll,
  AuthClaim.assignmentsDeleteAll,
  AuthClaim.systemEdit,
];

export type UserSchoolDetails = Partial<SchoolDetails & { adres: string }>;
export interface UserInfo {
  organizationName: string | null;
  address: string | null;
  nip: string | null;
  krs: string | null;
}
export interface User {
  id: string;
  displayName: string;
  email: string | null;
  createdAt: FirestoreTimestamp;

  role: UserRole;
  claims: AuthClaim[];

  avatarURL: string | null;
  school?: UserSchoolDetails;
  info?: UserInfo;

  requirePasswordReset?: boolean;
  // Coach Availability info
  availability?: {
    comment: string;
  };
}

export function displayNameForRole(role: UserRole) {
  switch (role) {
    case UserRole.unverified:
      return "Niezweryfikowany";
    case UserRole.admin:
      return "Administrator";
    case UserRole.normal:
      return "Realizator";
    case UserRole.school:
      return "Szkoła";
    case UserRole.coach:
      return "Trener";
  }
}

export const ALL_ROLES = [
  UserRole.unverified,
  UserRole.normal,
  UserRole.school,
  UserRole.coach,
  UserRole.admin,
];
