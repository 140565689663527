import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
};

function getApp(): firebase.app.App {
  let app: firebase.app.App;
  if (firebase.apps.length == 0) {
    app = firebase.initializeApp(firebaseConfig);
  } else {
    app = firebase.app();
  }
  return app;
}

export const firebaseApp = getApp();
export const firebaseFunctions = getApp().functions();
export const firestore = firebaseApp.firestore();
export const storage = firebaseApp.storage();
firebase.auth().languageCode = "pl";

export function now() {
  return firebase.firestore.Timestamp.now();
}
