import { init } from "next-firebase-auth";

const initAuth = () => {
  let firebase_private_key = process.env.FIREBASE_PRIVATE_KEY;
  let cookie_secret_current = process.env.COOKIE_SECRET_CURRENT;
  let cookie_secret_previous = process.env.COOKIE_SECRET_PREVIOUS;

  const projectId = process.env.NEXT_PUBLIC_PROJECT_ID;
  const authDomain = process.env.NEXT_PUBLIC_AUTH_DOMAIN;
  const apiKey = process.env.NEXT_PUBLIC_API_KEY;
  const clientEmail = process.env.CLIENT_EMAIL;
  const databaseURL = process.env.DATABASE_URL;

  if (!process.browser) {
    // THIS will be run
    // only when deployed on firebase.
    // Keys are set with firebase:config:set
    // const admin = require("firebase-admin");
    // const functions = require("firebase-functions");
    // console.log(`def: ${admin.apps.length}`);
    // if (admin.apps.length === 0) {
    //   admin.initializeApp();
    // }
    // firebase_private_key =
    //   functions.config().env?.config.firebase_private_key ??
    //   firebase_private_key;
    // cookie_secret_current =
    //   functions.config().env?.config.cookie_secret_current ??
    //   cookie_secret_current;
    // cookie_secret_previous =
    //   functions.config().env?.cookie_secret_previous ?? cookie_secret_previous;
  }

  const cookies_keys = [cookie_secret_current, cookie_secret_previous];

  init({
    authPageURL: "/auth",
    appPageURL: "/news",
    loginAPIEndpoint: "/api/login", // required
    logoutAPIEndpoint: "/api/logout", // required
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: {
        projectId: projectId!,
        clientEmail: clientEmail!,
        // The private key must not be accesssible on the client side.
        privateKey: (firebase_private_key as any)?.replace(/\\n/g, "\n"),
      },
      databaseURL: databaseURL!,
    },
    firebaseClientInitConfig: {
      apiKey: apiKey!, // required
      authDomain: authDomain,
      databaseURL: databaseURL,
      projectId: projectId,
    },
    cookies: {
      name: "IPZIN-Web", // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: cookies_keys,
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: false, //true, // set this to false in local (non-HTTPS) development
      signed: true, //true,
    },
  });
};

export default initAuth;
