import { ComponentType } from "react";

const LAYOUT_KEY = Symbol("layoutComponent");

export function assignLayout(Page: ComponentType, Layout: ComponentType) {
  Reflect.set(Page, LAYOUT_KEY, Layout);
}

export function renderPageWithLayout<P>(Page: any, props: P) {
  //export function renderPageWithLayout<P>(Page: ComponentType, props: P) {
  const LayoutComponent = Reflect.get(Page, LAYOUT_KEY) as
    | ComponentType
    | undefined;

  if (!LayoutComponent) {
    return <Page {...props} />;
  }

  return (
    <LayoutComponent>
      <Page {...props} />
    </LayoutComponent>
  );
}
