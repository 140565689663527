import { createTheme, rgbToHex } from "@mui/material/styles";
import { red } from "@mui/material/colors";
// in your theme file that you call `createTheme()`
import { Theme } from "@mui/material/styles";

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: rgbToHex("rgb(0, 140, 207)"),
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
