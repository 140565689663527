import firebase from "firebase/app";
import "firebase/firestore";
import { firestore } from "../utils/firebase";

export type FirestoreQuery<T> = firebase.firestore.Query<T>;
export type FirestoreDocumentSnapshot<T> = firebase.firestore.DocumentSnapshot<
  T
>;
export type FirestoreQuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>;

export type FirestoreCollectionReference<
  T
> = firebase.firestore.CollectionReference<T>;

const FIRESTORE = firestore;

export function collection<T>(name: string): FirestoreCollectionReference<T> {
  return FIRESTORE.collection(name) as FirestoreCollectionReference<T>;
}
