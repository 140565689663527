//NProgress.configure({ showSpinner: publicRuntimeConfig.NProgressShowSpinner });

import { useRouter } from "next/router";
import { useEffect } from "react";
import NProgress from "nprogress";

export default function DocumentProgressBar() {
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: any) => {
      //console.log(`ROUTER START ${url}`);
      if (url !== router.asPath) {
        NProgress.start();
      }
    };
    const handleComplete = (url: any) => {
      //console.log(`ROUTER COMPLETE ${url} | ${router.asPath}`);
      //if (url === router.asPath) {
      NProgress.done();
      //}
    };

    const handleError = (url: any) => {
      //console.log(`ROUTER ERROR. ${url}`);
      if (url === router.asPath) {
        NProgress.done();
      }
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleError);
    };
  });

  return <></>;
}
