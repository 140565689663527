import lodash from "lodash";
import { useRef } from "react";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { AlertType, useAlertsPresenter } from "~/components/AlertsPresenter";
import { Project } from "../Shared/Types/Project";
import { ProjectAssignment } from "../Shared/Types/ProjectAssignment";
import { User } from "../Shared/Types/User";
import { useFetchedUser } from "./queries";
import { useWarningOnUnsavedChanges } from "./React+States";

const UserContext = createContext<User | null>(null);
export function UserContextProvider({ children }: PropsWithChildren<{}>) {
  const user = useFetchedUser();

  // if (!user) {
  //   return <div>loading</div>;
  // }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUserOrNull() {
  const user = useContext(UserContext);
  return user;
}

/////////// EDIT MODE /////////////////
const EditModeContext = createContext<boolean>(false);
export function EditModeContextProvider({
  isEditMode,
  children,
}: PropsWithChildren<{ isEditMode: boolean }>) {
  const user = useFetchedUser();
  return (
    <EditModeContext.Provider value={isEditMode}>
      {children}
    </EditModeContext.Provider>
  );
}

export function useEditMode(): boolean {
  const isEditMode = useContext(EditModeContext);
  return isEditMode;
}
///////////////////////////////////

/// Project Page ////
interface ProjectPageEnvironment {
  project: Project | null;
}
const ProjectPageContext = createContext<ProjectPageEnvironment>({
  project: null,
});
export function ProjectPageContextProvider({
  info,
  children,
}: PropsWithChildren<{ info: ProjectPageEnvironment }>) {
  return (
    <ProjectPageContext.Provider value={info}>
      {children}
    </ProjectPageContext.Provider>
  );
}

export function useProjectPageContext(): ProjectPageEnvironment {
  const info = useContext(ProjectPageContext);
  return info;
}

/////////// Assignment page

interface AssignmentPageEnvironment {
  project: Readonly<Project | null>;
  assignment: Readonly<ProjectAssignment | null>;
}
const AssignmentPageContext = createContext<AssignmentPageEnvironment>({
  project: null,
  assignment: null,
});
export function AssignmentPageContextProvider({
  info,
  children,
}: PropsWithChildren<{ info: AssignmentPageEnvironment }>) {
  return (
    <AssignmentPageContext.Provider value={info}>
      {children}
    </AssignmentPageContext.Provider>
  );
}

export function useAssignmentPageContext(): AssignmentPageEnvironment {
  const info = useContext(AssignmentPageContext);
  return info;
}

/////////// App State /////////////////
export interface AppState {
  // that's a workaround so that we don't keep
  // redirecting the user to the resetPass page - even after he did change the pass
  // This should be handled differently.
  // User context should be eg a snapshot. Or moved to 'admin app'.
  // So that we don't access invalid cached user data
  _didRedirectToResetPass: boolean;
}

interface AppStateController {
  state: AppState;
  setState: (state: AppState) => void;
}

const AppStateContext = createContext<AppStateController | null>(null);

export function AppStateContextProvider({ children }: PropsWithChildren<{}>) {
  const [appState, setAppState] = useState<AppState>({
    _didRedirectToResetPass: false,
  });
  const stateController: AppStateController = {
    state: appState,
    setState: setAppState,
  };
  return (
    <AppStateContext.Provider value={stateController}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState(): [AppState, (s: AppState) => void] {
  const stateController = useContext(AppStateContext)!;

  const setState = (s: AppState) => {
    const clone = lodash.clone(s);
    stateController.setState(clone);
  };

  return [stateController.state, stateController.setState];
}

/////////// SAVE CONTEXT /////////////////
export type SaveAction = () => Promise<void>;
export interface SavableContextProps {
  save: () => Promise<void>;
  isDirty: boolean;
  isSaving: boolean;
  setSaveAction: (
    id: string,
    action: SaveAction | null,
    priority: number
  ) => void;
}
const SavableContext = createContext<SavableContextProps>({
  save: () => {
    return Promise.resolve();
  },
  isDirty: false,
  isSaving: false,
  setSaveAction: () => {},
});

type SaveActionsCache = {
  [key: string]: {
    id: string;
    action: SaveAction;
    priority: number;
  };
};

export function SavableContextProvider({ children }: PropsWithChildren<{}>) {
  const [isSaving, setIsSaving] = useState(false);
  const [registeredSaveActions, setRegisteredSaveActions] = useState<
    SaveActionsCache
  >({});
  const alertsPresenter = useAlertsPresenter();

  const isDirty = Object.values(registeredSaveActions).length > 0;
  useWarningOnUnsavedChanges(isDirty);

  const saveAllAction = async () => {
    setIsSaving(true);
    try {
      const actions = Object.values(registeredSaveActions);
      actions.sort((a, b) => b.priority - a.priority);
      for (const info of actions) {
        console.log(`Saving ${info.id}`);
        await info.action();
      }
      alertsPresenter.showAlert({
        type: AlertType.Success,
        message: `Zapisano zmiany`,
      });
    } catch (err) {
      const error = err as Error;
      alertsPresenter.showAlert({
        type: AlertType.Error,
        message: `${error.name}. ${error.message}`,
      });
    }
    setIsSaving(false);
  };

  const ref = useRef<
    (id: string, action: SaveAction | null, priority: number) => void
  >();

  function _setSaveAction(
    id: string,
    action: SaveAction | null,
    priority: number
  ) {
    if (action) {
      console.log(`Register save action: '${id}'`);
      registeredSaveActions[id] = {
        id,
        action,
        priority,
      };
      setRegisteredSaveActions({ ...registeredSaveActions });
    } else {
      if (registeredSaveActions[id]) {
        console.log(`Unregister save action '${id}'`);
        delete registeredSaveActions[id];
        setRegisteredSaveActions({ ...registeredSaveActions });
      }
    }
  }

  ref.current = _setSaveAction;
  const setSaveAction = (
    id: string,
    action: SaveAction | null,
    priority: number
  ) => {
    ref.current!(id, action, priority);
  };

  return (
    <SavableContext.Provider
      value={{ save: saveAllAction, isDirty, isSaving, setSaveAction }}
    >
      {children}
    </SavableContext.Provider>
  );
}

export function registerChildSaveAction(
  id: string,
  isDirty: boolean,
  action: SaveAction,
  priority: number = 0
) {
  const saveInfo = useSaveContext();

  // TODO: Uh,
  // ugly way to always have a up-to-date action reference
  // because stale action will be saved in dict
  // What's the better way to fix?
  const ref = useRef<SaveAction>();
  ref.current = action;
  const _action: SaveAction = async () => {
    await ref.current!();
  };

  useEffect(() => {
    function _register() {
      saveInfo.setSaveAction(id, isDirty ? _action : null, priority);
    }
    _register();

    return () => {
      saveInfo.setSaveAction(id, null, priority);
    };
  }, [isDirty]);
}

export function useSaveContext(): SavableContextProps {
  const saveInfo = useContext(SavableContext);
  return saveInfo;
}

///////////////////////////////////
