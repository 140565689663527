import firebase from "firebase/app";
import { useAuthUser } from "next-firebase-auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { User, UserRole } from "../Shared/Types/User";
import { UserCredential } from "../Shared/Types/UserCredential";
import { firebaseFunctions, firestore } from "./firebase";
import {
  projectsCollection,
  projectSharesCollection,
  userCredentials,
} from "./firebase/collections";
import lodash, { differenceWith, isEqual } from "lodash";
import { refreshGoogleAccessToken } from "../Shared/GoogleUtils";
import { Project } from "../Shared/Types/Project";

export function collection<T>(
  name: string
): firebase.firestore.CollectionReference<T> {
  return firestore.collection(name) as firebase.firestore.CollectionReference<
    T
  >;
}

export const usersCollection = collection<User>("users");

export function useFetchedUser() {
  const AuthUser = useAuthUser();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function fetchUser() {
      if (AuthUser.id) {
        const user = await getUser(AuthUser.id);
        setUser(user);
      }
    }

    fetchUser();
  }, [AuthUser]);

  return user;
}

export async function getUser(id: string): Promise<User> {
  const userSnap = await usersCollection.doc(id).get();
  const user = userSnap.data()!;
  return user;
}

export function userQuery<T>(
  query: firebase.firestore.Query<T>
): [T[], boolean] {
  const [results, setResults] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const snap = await query.get();
      const all = snap.docs.map((d) => d.data());
      setResults(all);
      setIsLoading(false);
    }

    fetch();
  }, []); // TODO: [query] ??

  return [results, isLoading];
}

export function useSnapshotOn<T>(
  doc: firebase.firestore.DocumentReference<T> | null
) {
  const [object, setObject] = useState<T | null>(null);

  useEffect(() => {
    if (!doc) {
      return () => {};
    }

    const cancelEvent = doc.onSnapshot(
      (snap) => {
        const data = snap.data();
        //console.log(`Snap update`);
        setObject(data ?? null);
      },
      (err) => {}
    );

    return () => {
      console.log(`RESET use-snapshot ${doc.path}`);
      cancelEvent();
    };
  }, [doc?.id]);

  return object;
}

export function useSnapshotOnQuery<T>(
  query: firebase.firestore.Query<T>
): [T[], boolean] {
  const [objects, setObjects] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const cancelEvent = query.onSnapshot(
      (snap) => {
        const docs = snap.docs;
        const items = docs.map((d) => d.data());
        setObjects(items);
        setIsLoading(false);
      },
      (err) => {
        console.log(`Error: ${err}`);
      }
    );

    return () => {
      cancelEvent();
    };
  }, []); // TODO: [query] ?

  return [objects, isLoading];
}

export function useDocument<T>(
  doc: firebase.firestore.DocumentReference<T>
): [T | null, boolean] {
  const [object, setObject] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      console.log(`Fetching doc.`);
      setIsLoading(true);
      const snap = await doc.get();
      setObject(snap.data()!);
      setIsLoading(false);
    }

    fetch();
  }, [doc.id]);

  return [object, isLoading];
}

export function useCredentials(): UserCredential | null {
  const authUser = useAuthUser();
  const doc = authUser.id ? userCredentials.doc(authUser.id) : null;
  const credentials = useSnapshotOn(doc);
  return credentials;
}

export function useRefreshedCredentials(): UserCredential | null {
  const credentials = useCredentials();

  const [refreshCredentials] = useAPICall<{}, UserCredential>(
    "refreshCredentials"
  );
  useEffect(() => {
    const refresh = async () => {
      await refreshCredentials();
    };
    refresh();
  });

  return credentials;
}

export function useAPICall<Input, Output>(
  name: string
): [(data?: Input) => Promise<Output>] {
  const task = firebaseFunctions.httpsCallable(name);

  async function call(data?: Input) {
    const response = await task(data);
    return response.data as Output;
  }

  return [call];
}

export function useMyProjectsQuery(): {
  projects: Project[];
  isLoading: boolean;
} {
  const authUser = useAuthUser();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      let fetchedProjects: Project[] = [];

      setIsLoading(true);
      try {
        if (authUser.claims["role"] === UserRole.admin) {
          console.log(`FETCH ALL`);
          const allProjectsSnap = await projectsCollection
            .orderBy("name")
            .get();
          const allProjects = allProjectsSnap.docs.map((s) => s.data()!);
          fetchedProjects = allProjects;
        } else {
          console.log(`My shares`);
          const mySharesSnap = await projectSharesCollection
            .where("userId", "==", authUser.id)
            .get();

          const myShares = mySharesSnap.docs.map((d) => d.data());
          const projectIds = myShares.map((s) => s.projectId);
          const allProjectsSnap = await Promise.all(
            projectIds.map((id) => projectsCollection.doc(id).get())
          );

          const allProjects = allProjectsSnap
            .filter((s) => s.data() !== undefined)
            .map((s) => s.data()!)
            .sort((a, b) => a.name.localeCompare(b.name));

          fetchedProjects = allProjects;
        }
      } catch (error) {
        console.error(`FETCH FAILED`);
        console.log(error);
      }

      setProjects(fetchedProjects);
      setIsLoading(false);
    }

    fetch();
  }, []);

  return { projects, isLoading };
}
