import { Project } from "../../Shared/Types/Project";
import { ProjectAssignment } from "../../Shared/Types/ProjectAssignment";
import { User } from "../../Shared/Types/User";
import { UserCredential } from "../../Shared/Types/UserCredential";
import { ProjectShare } from "../../Shared/Types/ProjectShare";
import { _ProjectAdditionalData } from "../../Shared/Types/ElementCustomFields";
import { UserAvailability } from "../../Shared/Types/UserAvailability";
import { userAvailabilityConverter } from "../../Shared/Types/UserAvailabilityConverter";
import {
  SystemTemplates,
  SystemProjectsPublisherConfig,
} from "../../Shared/Types/System/SystemTypes";
import { AsyncTask } from "../../Shared/Types/AsyncTask";
import { Post } from "../../Shared/Types/News/Post";
import { collection } from "~/Aliases";

export const usersCollection = collection<User>("users");
export const projectsCollection = collection<Project>("projects");
export const projectAssignmentsCollection = collection<ProjectAssignment>(
  "project-assignments"
);
export const userCredentials = collection<UserCredential>("credentials");

export const projectSharesCollection = collection<ProjectShare>(
  "share-projects"
);

export const projectAdditionalDataCollection = collection<
  _ProjectAdditionalData
>("projects-additional-data");

// User availability
// export const userAvailabilitiesCollection = collection<UserAvailability>(
//   "user-availabilities"
// ).withConverter(userAvailabilityConverter);

// System docs
export const systemAssignmentsExporterConfigDoc = collection<
  SystemProjectsPublisherConfig
>("system").doc("daemon-assignments-exporter");
export const systemTemplatesDoc = collection<SystemTemplates>("system").doc(
  "templates"
);

// Async tasks
export const asyncTasksCollection = collection<AsyncTask<any>>("async-tasks");

////////////////////
// Helper for making typed collections
////////////////////
// export function collection<T>(
//   name: string
// ): firebase.firestore.CollectionReference<T> {
//   return firestore.collection(name) as firebase.firestore.CollectionReference<
//     T
//   >;
// }
