import lodash from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export function useCloneState<S>(initialState: S | (() => S)): [s: S, set: (s: S) => void] {
  const [state, setState] = useState(initialState);
  const cloneAndSetState: (s: S) => void = (s: S) => {
    const clone = lodash.clone(s);
    setState(clone);
  };

  return [state, cloneAndSetState];
}

export function useWarningOnUnsavedChanges(hasChanges: boolean) {
  const router = useRouter();
  
  useEffect(() => {
    const warningText =
      "Masz niezapisane zmiany - czy na pewno chcesz opuścić tę stronę?";
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      
      if (!hasChanges) return;
      e.preventDefault();
      return (e.returnValue = warningText);
    };
    const handleBrowseAway = () => {
      if (!hasChanges) return;
      if (window.confirm(warningText)) return;
      router.events.emit("routeChangeError");
      throw "routeChange aborted.";
    };

    window.addEventListener("beforeunload", handleWindowClose);
    router.events.on("routeChangeStart", handleBrowseAway);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
      router.events.off("routeChangeStart", handleBrowseAway);
    };
  }, [hasChanges]);

}