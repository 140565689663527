import React, { useEffect, useState } from "react";

import "../styles/globals.css";
import "../styles/TextEditor.css";
import "../styles/styles.css";
import "../styles/nprogress.css";
import "../styles/calendar.css";

// Full calendar CSS
import "@fullcalendar/common/main.css"; // @fullcalendar/react imports @fullcalendar/common
import "@fullcalendar/daygrid/main.css"; // @fullcalendar/react imports @fullcalendar/common
import "@fullcalendar/list/main.css"

import type { AppProps } from "next/app";
import { Theme, StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../theme/theme";
import AdminApp from "../components/apps/AdminApp";
import { withAuthUser } from "next-firebase-auth";
import {
  UserContextProvider,
  AppStateContextProvider,
} from "../utils/contexts";
import initAuth from "../utils/auth/initAuth";
import { useRouter } from "next/router";
import Head from "next/head";
import DocumentProgressBar from "../components/Generic/DocumentProgressBar";
import { Provider } from "next-auth/client";
import { renderPageWithLayout } from "~/layouts/assignLayout";
import {IntlProvider} from 'react-intl'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Comment: https://mobx.js.org/configuration.html
// Debugging.
// configure({
//   enforceActions: "always",
//   computedRequiresReaction: true,
//   reactionRequiresObservable: true,
//   observableRequiresReaction: true,
//   disableErrorBoundaries: true
// })

////////////////////
// Configure Auth //
initAuth();
////////////////////

import * as DEFAULT_MESSAGES from "~/../compiled-lang/pl.json" 
// const DEFAULT_MESSAGES = import('~/../compiled-lang/pl.json')

function RootApp({ Component, pageProps }: AppProps) {

  const router = useRouter()
  const [MESSAGES, SET_MESSAGES] = useState<any>(DEFAULT_MESSAGES)
  const RESOLVED_LOCALE = router.locale ?? "pl"

  useEffect(() => {
    async function load() {
      function loadLocaleData(locale: string) {
        console.log(`Loading locale: ${locale}`)
        switch (locale) {
          case 'pl':
            return import('~/../compiled-lang/pl.json')
            case 'en-US':
              return import('~/../compiled-lang/en.json')
          default:
            return import('~/../compiled-lang/pl.json')
        }
      }
      
      const data = await loadLocaleData(RESOLVED_LOCALE)
      SET_MESSAGES( data )
    }
    load()
    
  }, [RESOLVED_LOCALE])

  return (
    // <SessionProvider session={pageProps.session}>
    <IntlProvider messages={MESSAGES} locale={RESOLVED_LOCALE} defaultLocale={RESOLVED_LOCALE}>
    <Provider session={pageProps.session}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <AppStateContextProvider>
              <UserContextProvider>
                {renderPageWithLayout(Component, pageProps)}
                <DocumentProgressBar />
              </UserContextProvider>
          </AppStateContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      {/* </SessionProvider> */}
    </Provider>
    </IntlProvider>
  );
}

export default withAuthUser<any>({
  // whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
})(RootApp);
